import '../../../scss/components/cms/_cms-category-list.scss';
import '../../../scss/critical_complete.scss';
import '../../../scss/components/_hero.scss';

import Vue from 'vue';
import ConversionPairSelector from '../../qaamgo/modules/sat/components/conversionpair/ConversionPairSelector.vue';

const $ = require('jquery');
global.$ = global.jQuery = $;

$(document).ready(function () {
    import(
        /* webpackMode: "lazy" */
        '../qg-main'
    ).then(function () {
        const conversionPairSelector = document.getElementById('conversionPairSelector');
        const pageType = document.getElementById('pageType');

        if (conversionPairSelector) {
            import(
                /* webpackMode: "lazy-once" */
                `../translations/sat-translations.js`
            ).then(function () {
                new Vue({
                    el: '#conversionPairSelector',
                    name: 'ConversionPairSelectorRoot',
                    components: { ConversionPairSelector },
                    render(h) {
                        return h(ConversionPairSelector, { props: { pageType: pageType.dataset.pageType } });
                    },
                });
            });
        }
    });
});
